<template>
  <Section id="about" class="white" light>
    <SectionHeading :title="sectionHeadingTitle" size="text-h3" />

    <v-container>
      <v-row>
        <v-col v-for="d in page_data" :key="d.id" cols="12" md="4">
          <CustomInfoCard v-bind="d" />
        </v-col>
      </v-row>
    </v-container>
  </Section>
</template>

<script>
import Section from "../subcomponents/Section.vue";
import CustomInfoCard from "../subcomponents/CustomInfoCard.vue";
import SectionHeading from "../subcomponents/SectionHeading.vue";
// import { getRobotList } from "@/services/api/robot";

export default {
  name: "SectionAboutOurProduct",
  components: { Section, CustomInfoCard, SectionHeading },
  data: () => ({
    sectionHeadingTitle: "เกี่ยวกับเรา",
    page_data: [
      {
        title: "งานวิจัยและพัฒนา",
        subtitle: "สิ่งที่ดีที่สุด",
        text: "เรามีความตั้งใจและนำเทคโลโลยีที่ดีที่สุด มาใช้วิเคราะห์ข้อมูลใน Cryptocurrency เพื่อให้ได้สิ่งที่ดีที่สุด",
        callout: "01",
      },
      {
        title: "มีความเหมาะสม",
        subtitle: "การใช้งาน",
        text: "เลือกสิ่งที่เหมาะสมและคำนึงถึงการใช้งานได้จริง ไม่ใช่เพียงแค่ได้ผลลัพธ์ที่ถูกนำไปใช้งานต่อไม่ได้",
        callout: "02",
      },
      {
        title: "เข้าถึงบริการได้ง่าย",
        subtitle: "ผู้ใช้งาน",
        text: "นำเทคโนโลยีที่เข้าถึงได้ยากอย่าง A.I. Deep Learning มาให้ใกล้ตัวนักลงทุนทั่วไปมากที่สุด",
        callout: "03",
      },
    ],
  }),
  methods: {
    // async getData() {
    //   let _page_data = await getRobotList();
    //   for (let i = 0; i < _page_data.length; i++) {
    //     _page_data[i]["monthly_return"] =
    //       _page_data[i]["monthly_return"].toFixed(2);
    //     _page_data[i]["maximum_drawdown"] =
    //       _page_data[i]["maximum_drawdown"].toFixed(2);
    //   }
    //   this.page_data = _page_data;
    // },
  },
  mounted() {
    // this.getData();
  },
};
</script>
