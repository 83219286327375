<template>
  <Section id="features" color="secondary">
    <v-responsive class="mx-auto" max-width="1350">
      <v-container fluid>
        <v-row>
          <v-col
            v-for="card in cards"
            :key="card.title"
            cols="12"
            sm="4"
            md="3"
          >
            <InfoCard align="center"  v-bind="card" />
          </v-col>
        </v-row>
      </v-container>
    </v-responsive>
  </Section>
</template>

<script>
import InfoCard from "../subcomponents/InfoCard.vue";
import Section from "../subcomponents/Section.vue";
export default {
  name: "SectionFeatures",
  data: () => ({
    cards: [
      {
        icon: "mdi-robot",
        title: "Features",
        text: "ถูกออกแบบโดยนักพัฒนาและนักวิจัยด้านวิทยาการข้อมูล ให้ทำงานได้รวดเร็ว",
        color: '#42A5F6',
      },
      {
        icon: "mdi-drawing-box",
        title: "Design",
        text: "ให้มีความสวยงามและใช้งานได้ง่ายที่สุด พร้อมคู่มือต่าง ๆ",
        color: '#42A5F6',
      },
      {
        icon: "mdi-cloud",
        title: "Cloud Service",
        text: "โรบอทและเครื่องมือต่าง ๆ พัฒนาบน Google Cloud Platform มั่นใจได้ Uptime 99% ไม่ล่ม",
        color: '#42A5F6',
      },
      {
        icon: "mdi-puzzle-outline",
        title: "Support",
        text: "แชทกับทีมงานได้ตลอดเวลาทำการ ไม่ว่าปัญหาการใช้งานหรือสอบถามเพิ่มเติม ผ่าน Fanpage ของเรา",
        color: '#42A5F6',
      },
    ],
  }),
  components: { InfoCard, Section },
};
</script>
