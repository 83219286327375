<template>
  <Section id="highlight" light class="grey lighten-4">
    <v-container fluid>
      <v-row>
        <v-col cols="12" md="4">
          <v-responsive class="mx-auto" max-width="460">
            <InfoCard
              :subtitle="subTitleText"
              :title="titleText"
              color="grey lighten-4"
            />

            <Body class="grey--text text--darken-1">
              ตัวอย่างประสิทธิภาพของโรบอทเทรด BTC Future ชื่อ Bit Warrior
              ที่เทรดอย่างปลอดภัยด้วยโมเดล Deep Learning ที่ถูกเทรนมาเป็นอย่างดี
              อัตโนมัติ 100% ไม่ต้องเฝ้าจอผ่าน โดยเชื่อมต่อผ่าน Binance API
            </Body>
            <Btn
              color="#1976D2"
              class="white--text"
              to="/robot?code=pro_MCn2Sniper_future_BTCUSDT"
            >
              {{ btnText }}
            </Btn>
          </v-responsive>
        </v-col>

        <v-col class="secondary d-flex flex-wrap child-flex" cols="12" md="4">
          <v-sparkline :smooth="16" :gradient="gradient" :value="value" fill />
        </v-col>

        <v-col class="" cols="12" md="4">
          <v-responsive class="mx-auto" max-width="460">
            <InfoCard
              subtitle="Back & Forward Test"
              title="Statistics"
              color="grey"
            />

            <Progress
              v-for="([title, forTextVal, val, color], i) in progress"
              :key="i"
              :title="`${title} ${forTextVal}%`"
              :value="val"
              :color="color"
              :height="heightProgress"
            />
          </v-responsive>
        </v-col>
      </v-row>
    </v-container>
  </Section>
</template>

<script>
import Section from "../subcomponents/Section.vue";
import Body from "../subcomponents/Body.vue";
import InfoCard from "../subcomponents/InfoCard.vue";
import Progress from "../subcomponents/Progress.vue";
import Btn from "../subcomponents/Btn.vue";
import { getCommerciaBotStats } from "@/services/api/commercial";

export default {
  name: "SectionInfoGraph",
  components: {
    Section,
    Body,
    InfoCard,
    Progress,
    Btn,
  },
  data: () => ({
    heightProgress: 8,
    progress: [
      ["Monthly Return", 0, 0, "#4CAF50"],
      ["Maximum Drawdown", 0, 0, "#F44336"],
      ["%Win", 0, 0, "#2196F3"],
    ],
    value: [],
    subTitleText: "ตัวอย่างโรบอทเทรด",
    titleText: "Bit Warrior",
    btnText: "เรียนรู้เพิ่มเติม",
    btnLink: "",
    robot_id: "pro_MC6x_future_BTCUSDT",
    chart_data: {},
    stats_data: {},
  }),

  computed: {
    gradient() {
      const { primary, accent } = this.$vuetify.theme.themes.light;
      return [primary, accent];
    },
  },
  mounted() {
    this.getRobotData();
  },
  methods: {
    async getRobotData() {
      this.chart_data = await getCommerciaBotStats(this.robot_id);
      if (this.chart_data === undefined) {
        // error
      } else {
        this.value =
          this.chart_data["chart_data"]["backtestWeekChart"]["value"];
        // Monthly Return - <ค่าสถิติได้จาก API> (หลอดสีเขียว สเกลเต็ม 20)
        // Maximum Drawdown - <ค่าสถิติได้จาก API> (หลอดสีแดง เต็ม 50)
        // %Win - <ค่าสถิติได้จาก API> (หลอดสีน้ำเงิน สเกลเต็ม 100)
        //
        // +20.0
        // -50.0
        // 100.0
        this.progress[0][1] = this.chart_data["stats"][0]["value"].toFixed(2);
        this.progress[1][1] = this.chart_data["stats"][1]["value"].toFixed(2);
        this.progress[2][1] = this.chart_data["stats"][2]["value"].toFixed(2);
        //
        this.progress[0][2] =
          (this.chart_data["stats"][0]["value"].toFixed(2) / 20) * 100;
        this.progress[1][2] =
          (this.chart_data["stats"][1]["value"].toFixed(2) / -50) * 100;
        this.progress[2][2] = this.chart_data["stats"][2]["value"].toFixed(2);
      }
    },
    // get statistic data
  },
};
</script>
