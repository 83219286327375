<template>
  <v-card :color="color" :elevation="value ? 12 : 0" class="pa-2" outlined tile>
    <v-card flat outlined tile>
      <div class="pa-6 pb-4 mb-1">
        <Title size="text-h5" :title="title" class="text-uppercase" space="1" />
        <Subtitle :title="subtitle" space="8" />

        <div class="d-flex align-center justify-center" v-if="price !== 0">
          <Heading mobile-size="display-2" space="0" tag="h3">
            <sup><small>฿</small></sup
            >{{ price.toLocaleString() }}
          </Heading>

          <div
            class="text-left ml-4 body-2 font-weight-bold mt-1 text-uppercase text-center"
          >
            ต่อ
            <Divider space="0" color="#1976D2" />
            {{ intervalText }}
          </div>
        </div>
        <!-- -->
        <div class="d-flex align-center justify-center" v-if="price === 0">
          <Heading mobile-size="display-2" space="0" tag="h3"> Free </Heading>
        </div>
      </div>

      <div class="pb-1">
        <div class="px-8 mb-1">
          <div v-if="!redTextSaving">
            <template v-for="(feature, i) in features">
              <div
                v-if="feature === 'จำกัดมูลค่าพอร์ท < 300 USDT'"
                :key="feature"
                class="my-3 red--text"
                v-text="feature"
              />
              <div v-else :key="feature" class="my-3" v-text="feature" />

              <v-divider
                v-if="i + 1 < features.length"
                :key="`divider-${i}`"
                color="white"
              />
            </template>
          </div>

          <!-- -->
          <div v-if="redTextSaving">
            <template v-for="(feature, i) in features">
              <div v-if="i != 0" :key="feature" class="my-3" v-text="feature" />
              <div
                v-else
                :key="feature"
                class="my-3 red--text"
                v-text="feature"
              />
              <v-divider
                v-if="i + 1 < features.length"
                :key="`divider-${i}`"
                color="white"
              />
            </template>
          </div>
        </div>
      </div>
    </v-card>
  </v-card>
</template>

<script>
import Title from "./Title.vue";
import Subtitle from "./Subtitle.vue";
import Heading from "./Heading.vue";
import Divider from "./Divider.vue";

export default {
  name: "PriceCard",
  provide: {
    heading: { align: "center" },
  },
  props: {
    features: {
      type: Array,
      default: () => [],
    },
    redTextSaving: Boolean,
    price: [Number, String],
    subtitle: String,
    title: String,
    interval: Number,
    intervalText: String,
    intervalUnit: String,
    value: Boolean,
  },
  computed: {
    color() {
      return "white";
      // if (!this.value) return undefined;
      // return this.$vuetify.isDark ? "white" : "grey lighten-5";
    },
  },
  components: { Title, Subtitle, Heading, Divider },
};
</script>
