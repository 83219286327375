<template>
  <Section id="docs" space="0">
    <v-container fluid>
      <v-row justify="center">
        <v-theme-provider>
          <v-col class="secondary extra-padding" cols="12" md="4">
            <v-responsive class="mx-auto" max-width="360">
              <Subtitle title="ใช้งานอย่างไร" space="2" />

              <Title
                mobile-size="title"
                size="display-1"
                space="2"
                title="คู่มือการใช้งาน"
              />

              <Divider color="primary" />

              <Body :space="$vuetify.breakpoint.smAndDown ? 0 : undefined">
                คู่มือการใช้งานอย่างง่าย ไม่เกิน 5
                นาทีก็สามารถใช้งานได้หรือเรียนรู้วิธีการพิจารณาเลือกใช้โรบอท
                เนื้อหาจริงใจ เปิดกว้างไม่ใช่เพียงของเราเท่านั้น
                แต่นำไปพิจารณากับแพลตฟอร์มอื่นได้อีก
              </Body>

              <v-item-group v-model="filterIndex">
                <template v-for="(c, i) in categories">
                  <v-item :key="i" :value="c">
                    <v-btn
                      :input-value="i === filterIndex"
                      class="justify-start mb-1"
                      color="grey lighten-1"
                      text
                      width="300"
                      @click="changeView(i)"
                    >
                      {{ c }}
                    </v-btn>
                  </v-item>

                  <br v-if="i < categories.length" :key="`br-${i}`" />
                </template>
              </v-item-group>
            </v-responsive>
          </v-col>
        </v-theme-provider>

        <v-col class="grey lighten-4" cols="12" md="8">
          <CustomProjects :projectData="filterSectionData" />
        </v-col>
      </v-row>
    </v-container>
  </Section>
</template>

<script>
import Section from "../subcomponents/Section.vue";
import Subtitle from "../subcomponents/Subtitle.vue";
import Title from "../subcomponents/Title.vue";
import Divider from "../subcomponents/Divider.vue";
import Body from "../subcomponents/Body.vue";
import CustomProjects from "./CustomProjects.vue";

export default {
  name: "SectionRecentWork",

  components: {
    Section,
    Subtitle,
    Title,
    Divider,
    Body,
    CustomProjects,
  },

  data: () => ({
    filterIndex: 0,
    category: "การสมัครใช้งาน",
    categories: [
      "การสมัครใช้งาน",
      "การเชื่อมต่อกับ Binance API Key",
      "การเติมเงินเข้าบัญชี",
      "เทรดด้วยโรบอทต้องดูอะไร",
    ],
    filterSectionData: {},
    sectionData: [
      {
        title: "การสมัครใช้งาน",
        text: "เพียงเข้าเว็บไซต์หรือดาวโหลดแอปฯ Crypto-Mation จากนั้นสามารถใช้งานฟีเจอร์ต่าง ๆ ได้ฟรี หรือจะเลือกเทรดด้วยโรบอท เพียงแค่ท่านเลือกโรบอทที่ท่านถูกใจ จากนั้นกดซื้อและเลือกแพ็กเกจ",
        index: 0,
        goTo: "/docs/registerbot",
      },
      {
        title: "การเชื่อมต่อกับ Binance API Key",
        text: "ในขั้นตอนการสมัครใช้งานโรบอทเทรด จะต้องทำการเชื่อมต่อกับ Binance API Key เพื่อให้สิทธิเราในการเทรดในตลาด Future ซึ่งคุณจะต้องตั้งค่าสิทธิตามรูปภาพด้านล่างเท่านั้น เพื่อความปลอดภัยต่อบัญชีของท่าน",
        index: 1,
        subtitle:
          "ถ้าท่านไม่สามารถตั้งค่าสิทธิตามภาพได้เป็นไปได้ว่าท่านยังไม่ได้เปิดบัญชีเทรด Future กับ Binanceให้ไปดูคู่มือ",
        goTo: "/docs/apikey",
      },

      {
        title: "การเติมเงินเข้าบัญชี",
        text: "การเติมเงินเข้าไปยังกระเป๋า Future บนแพลตฟอร์ม Binance ท่านจะต้องซื้อเหรียญ USDT จาก P2P และทำการโอนเงินไปยังกระเป๋า Spot หรือเติม USDT จากแพลตฟอร์มอื่น ๆ เข้ามายังกระเป๋า Spot จากนั้น ทำการโอนเหรียญจาก Spot ไป USD-M Future ก็เป็นอันเสร็จเรียบร้อยหรือขอเพียงท่านดูคลิปสั้น ๆ ของเราก็เป็นเรื่องง่ายในทันที",
        index: 2,
        goTo: "/docs/transfermoney",
      },

      {
        title: "เทรดด้วยโรบอทต้องดูอะไร",
        text: "การเทรดด้วยโรบอท ไม่ว่าจะเทรดในตลาดใด ๆ ก็แล้วแต่ ท่านจะต้องพิจารณาจากอะไรบ้าง ในขั้นพื้นฐาน โดยทาง Crypto-Mation มีการรีวิวโรบอทเจ้าอื่น ๆ ให้ท่านได้พิจารณาด้วย อย่างจริงใจ เป็นวิทยาศาสตร์ ไม่หมกเม็ด",
        index: 3,
        goTo: "",
      },
    ],
  }),
  mounted() {
    this.filterSectionData = this.sectionData[this.filterIndex];
  },
  methods: {
    changeView(dataIndex) {
      this.filterIndex = dataIndex;
      this.filterSectionData = this.filterSectionData =
        this.sectionData[this.filterIndex];
    },
  },
};
</script>
