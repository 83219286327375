<template>
  <div class="mb-8">
    <Title
      :title="title"
      class="pl-2 text-uppercase"
      size="subtitle-2"
      space="2"
    />

    <v-progress-linear :value="value" :color="color" :height="height" background-color="grey lighten-2" />
  </div>
</template>

<script>
import Title from "./Title.vue";
export default {
  name: "BaseProgress",
  components: { Title },

  props: {
    title: String,
    value: [Number, String],
    color: {
      type: String,
      default: 'blue'
    },
    height: {
      type: Number,
      default: 2,
    }
  },
};
</script>
