<template>
  <div
    :class="classes"
    class="pt-2"
  >
    <Avatar
      v-if="icon"
      :color="color"
      :dark="dark"
      :icon="icon"
      :outlined="outlined"
      :size="size"
      class="mb-3 align-self-start"
    />

    <div :class="horizontal && title && 'ml-6'">
      <Title
        :title="title"
        class="text-uppercase"
        space="3"
      />
      <Body
        v-if="text || $slots.default"
        :space="horizontal ? 0 : undefined"
        :text="text"
        class="mx-auto"
        max-width="700"
        
      >
        <slot />
      </Body>
        <div v-for="(tag, i) in tags" :key="i" cols="12" md="6" style="display: inline-block;">
          <v-chip
            class="ma-1"
            :color="tagColors[i]"
            text-color="white"
          >{{ tag }}
          </v-chip>
        </div>
    </div>
  </div>
</template>

<script>
  // Mixins
  import Heading from '@/mixins/heading'
  import Avatar from './Avatar.vue'
  import Title from './Title.vue';
  import Body from './Body.vue';

  export default {
    name: "BaseAvatarCard",
    mixins: [Heading],
    props: {
        align: {
            type: String,
            default: "left",
        },
        color: String,
        dark: Boolean,
        horizontal: Boolean,
        icon: String,
        outlined: {
            type: Boolean,
            default: true,
        },
        space: {
            type: [Number, String],
            default: 8,
        },
        size: {
            type: [Number, String],
            default: 72,
        },
        text: String,
        title: String,
        tags: Array,
        tagColors: Array,
    },
    computed: {
        classes() {
            const classes = [
                `mb-${this.space}`,
            ];
            if (this.horizontal) {
                classes.push("d-flex");
                if (!this.$slots.default && !this.text) {
                    classes.push("align-center");
                }
            }
            return classes;
        },
    },
    components: { Avatar, Title, Body }
}
</script>
